import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import './stylesheets/signup.css'
import Modal from "react-bootstrap/Modal"
import { Base64 } from 'js-base64';
import ReCAPTCHA from "react-google-recaptcha"
import {Translator, Translate} from 'react-auto-translate';
import serverUrl from '../../configs/constant'
import {
  validateExistence,
  validateEmail,
  validateLength,
  validateLowerCase,
  validateUpperCase
} from './utils/validation'

function onChange(value) {
  console.log("Captcha value:", value);
}

export default class signup extends Component {
  constructor(props) {
    super(props);
    var affCode = '';
    var buttonCondition = 1;
    if(this.props.match.params.affcode !== undefined && this.props.match.params.affcode !== '' && this.props.match.params.affcode !== null){
      var affCodeData = this.props.match.params.affcode;
      let baseToString = Base64.decode(affCodeData);
      var stringSpite = baseToString.split('-')
      affCodeData = stringSpite[0];
      affCode = affCodeData;
      buttonCondition = 0;
    }
    this.state = {
        isOpen: true,
        aff_code:'0',
        menu: false,
        firstname: '',
        middlename: '',
        lastname: '',
        affiliate_code: affCode,
        affiliate_codeDe:affCode,
        buttonCondition:buttonCondition,
        birth_year: '',
        birth_month: '',
        birth_date: '',
        password: '',
        password_confirmation: '',
        password_hint: '',
        mobile_no: '',
        company_name: '',
        email: '',
        email_confirm: '',
        mobile_phone_country_code: '',
        mobile_no: '',
        email_confirm: '',
        contoryCodeData:'',
        countryCodeDataImg:'',
        we_chat: '0',
        what_app: '0',
        dayData:0,
        monthData:0,
        uesrtypedata:this.props.match.params.registervalue,
        devicetype:localStorage.getItem('devicetype'),
        deviceos:localStorage.getItem('deviceos'),
        devicebrowser:localStorage.getItem('devicebrowser'),
        latitude: localStorage.getItem('latitude'),
        longitude:localStorage.getItem('longitude'),
        userdevice:localStorage.getItem('userdevice'),
        hoveAff:'form-control title_check border-error',
        selectedLanguage:localStorage.getItem('selectedLanguage'),
        preselectedLanguage:localStorage.getItem('preselectedLanguage'),
      };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    window.scrollTo(0, 0);
    var latitude = this.state.latitude;
    var longitude = this.state.longitude;
    var devicebrowser = this.state.devicebrowser;
    var deviceos = this.state.deviceos;
    var devicetype = this.state.devicetype;
    var userdevice = this.state.userdevice;
    const fetchDataPage = async() =>{
      var sendData = {
        "devicetype":devicetype,
        "deviceos":deviceos,
        "devicebrowser":devicebrowser,
        "latitude":latitude,
        "longitude":longitude,
        "userdevice":userdevice,
      }
        var reAddData = await this.props.getSigninData(sendData);
        this.setState({
          pageDetails:reAddData.data
        })
       }
       fetchDataPage();
    if(this.state.affiliate_code === ''){
      var sendData = {
        "devicetype":devicetype,
        "deviceos":deviceos,
        "devicebrowser":devicebrowser,
        "latitude":latitude,
        "longitude":longitude,
        "userdevice":userdevice,
      }
       // this.props.getSigninData(sendData);

       const fetchData = async() =>{
        // const  pageDetails  = await this.props.signData;
        var reAddData = await this.props.getSigninData(sendData);
        this.setState({
          affiliate_code:reAddData.data.DefaultAffiliate,
          affiliate_codeDe:reAddData.data.DefaultAffiliate,
          // pageDetails:pageDetails
        })
       }
       fetchData();
    }
  }
  toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }

  handleAffiliateCode= (event) => {
    // this.setState({
    //   affiliate_code:event
    // });
  }

  handleFormSubmit = event => {
    event.preventDefault();
    // console.log('this.state',this.state);
    if(this.state.uesrtypedata === 'Buyer'){
      this.props.signinBuyer(this.state);
    }else if(this.state.uesrtypedata === 'Affiliate'){
      this.props.signinAffiliate(this.state);
    }else{
      this.props.signinVendor(this.state);
    }
    // const validation = this.validator.validate(this.state);
    // this.setState({
    //   validation
    // });
  }

  handleInputChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
    if(event.target.name !== 'middlename'){
      if(event.target.value !== ''){
        event.currentTarget.classList.remove('border-error')
        event.currentTarget.classList.add('border-success')
      }else{
        event.currentTarget.classList.remove('border-success')
        event.currentTarget.classList.add('border-error')
      }
      if(event.target.name === 'email_confirm'){
        if(event.target.value === this.state.email){
          event.currentTarget.classList.remove('border-error')
          event.currentTarget.classList.add('border-success')
        }else{
          event.currentTarget.classList.remove('border-success')
          event.currentTarget.classList.add('border-error')
        }
      }
    }
  }  

  handleInputChangeOtherCountry = (flag,code,flagurl) => {
    this.setState({ menu: !this.state.menu })
    // event.preventDefault();
    this.setState({
      contoryCodeData:code,
      countryCodeDataImg:flagurl+flag,
      mobile_phone_country_code:code
    });
    // if(event.target.value !== ''){
    //   event.currentTarget.classList.remove('border-error')
    //   event.currentTarget.classList.add('border-success')
    // }else{
    //   event.currentTarget.classList.remove('border-success')
    //   event.currentTarget.classList.add('border-error')
    // }
  }

  handleInputChangeRadio = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }  
  

  handleInputChangeYear = event => {
    var d = new Date();
    var day = d.getDate();
    var dayData = 0;
    var monthData = 0;
    var n = d.getFullYear();
    var currentyear = n - 18;
    if(currentyear === parseInt(event.target.value)){
      dayData = day - 1;
      monthData = d.getMonth();
    }
    this.setState({
      [event.target.name]: event.target.value,
      dayData:dayData,
      monthData:monthData,
    });
    if(event.target.value !== ''){
      event.currentTarget.classList.remove('border-error')
      event.currentTarget.classList.add('border-success')
    }else{
      event.currentTarget.classList.remove('border-success')
      event.currentTarget.classList.add('border-error')
    }
  }  

handleInputChangeMonth = event => {
    var d = new Date();
    var day = d.getDate();
    var dayData = 0;
    var monthData = 0;
    var monthDataMain =d.getMonth() + 1;
    var n = d.getFullYear();
    var currentyear = n - 18;
    var currentyearMian = this.state.birth_year;
    if(currentyear === parseInt(currentyearMian) && monthDataMain === parseInt(event.target.value)){
      dayData = day - 1;
    }
    this.setState({
      [event.target.name]: event.target.value,
      dayData:dayData,
    });
    if(event.target.value !== ''){
      event.currentTarget.classList.remove('border-error')
      event.currentTarget.classList.add('border-success')
    }else{
      event.currentTarget.classList.remove('border-success')
      event.currentTarget.classList.add('border-error')
    }
  }  


  handleAffiliateChange= event => {
    let affilatecode = event.target.value;
    if(affilatecode !==''){
      let requestData = {};
      requestData.affilatecode = affilatecode;
      requestData.devicetype= this.state.devicetype;
      requestData.deviceos= this.state.deviceos;
      requestData.devicebrowser= this.state.devicebrowser;
      requestData.latitude= this.state.latitude;
      requestData.longitude= this.state.longitude;
      requestData.userdevice= this.state.userdevice;
      this.props.getAffilate(requestData); 
    }
    
  }

  render() {
    const  pageDetails  = this.state.pageDetails;
    const  affliateDetails  = this.props.affliateData;
    const  vendorDetails  = this.props.vendorData;

    var userType = this.state.uesrtypedata;
    var registerType = this.state.uesrtypedata;
    var openModelData = false;
    var msgMain = '';

    var optionserror = [];


    if(vendorDetails !=='' && vendorDetails !== undefined){
      if(vendorDetails.status === '0' ){
        var errorMain = vendorDetails.errors;
        for (var i = 0; i < errorMain.length; i++) {
          var objerorr = {};
          objerorr['msgerror'] = errorMain[i].msg;
          optionserror.push(objerorr);
        }
      }else if(vendorDetails.status === '1' ){
        var msgMain = vendorDetails.msg;
      }
    }

    const optionsday = [];
    const optionsmonth = [];
    const optionsyear = [];
    const countryData = [];
    var dayData = this.state.dayData;
    var monthData = this.state.monthData;
    const days = ['01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    const month = ['01','02','03','04','05','06','07','08','09',10,11,12];
    const years = [];
    var objday = {}
    var objmonth = {}
    var objyear = {}
    var countryDataMain = {}
    var responcedata = serverUrl.appname+' Default AAA0001'
    var msgClass = 'respaffgreen';
    
    if(affliateDetails !=='' && affliateDetails !== undefined){
      if(affliateDetails.data.status === '1'){
        var responcedata = affliateDetails.data.affilate.FirstName+' '+affliateDetails.data.affilate.LastName;
      }else{
        msgClass ='respaffred';
        var responcedata = 'Affiliate number should follow this structure: '+this.state.affiliate_codeDe;
      }
    }else{
      var responcedata = '';
    }

    const show = (this.state.menu) ? "show" : "" ;

    var d = new Date();
    var n = d.getFullYear();
    var currentyear = n - 18;

    for(var i = currentyear; i>=1920; i--) {
      var objyear = {};

      objyear['value'] = i;
      objyear['label'] = i;
      optionsyear.push(objyear);
    }

    if(monthData !== 0){
      

      for(var i = 0; i <= monthData; i++) {
        var objmonth = {};

        objmonth['value'] = month[i];
        objmonth['label'] = month[i];
        optionsmonth.push(objmonth);
      }    
    }else{
      for(var i = 0; i < month.length; i++) {
        var objmonth = {};

        objmonth['value'] = month[i];
        objmonth['label'] = month[i];
        optionsmonth.push(objmonth);
      }    
    }
    if(dayData !== 0){
      for(var i = 0; i <= dayData; i++) {
          var objday = {};

          objday['value'] = days[i];
          objday['label'] = days[i];
          optionsday.push(objday);
        }
    }else{
      for(var i = 0; i < days.length; i++) {
        var objday = {};

        objday['value'] = days[i];
        objday['label'] = days[i];
        optionsday.push(objday);
      }
    }

    if(pageDetails !== null && pageDetails !== undefined){
      var DefaultAffiliate = pageDetails.DefaultAffiliate;
      var CountryName = pageDetails.CountryCode;
      if(this.state.contoryCodeData === ''){
        var codeNameone = pageDetails.calling_code;
      }else{
        var codeNameone = this.state.contoryCodeData;
      }

      if(this.state.countryCodeDataImg === ''){
        var codeimgone = pageDetails.flagURL+pageDetails.country_code+'.png';
      }else{
        var codeimgone = this.state.countryCodeDataImg;
      }

      // var codeimgone = pageDetails.flagURL+pageDetails.country_code+'.png';
      for(var i = 0; i < CountryName.length; i++) {
        var countryDataMain = {};
        countryDataMain['name'] = CountryName[i].CountryName;
        countryDataMain['flagurl'] = pageDetails.flagURL;
        countryDataMain['flagname'] = CountryName[i].CountryFlag;
        countryDataMain['phonecode'] = CountryName[i].CountryPhoneCode;
        countryData.push(countryDataMain);
      }
    }else{
      var DefaultAffiliate = 'AAA0001';
      
      var CountryName = 'IN';
      var codeNameone = 91;
      var codeimgone = 'https://globalbox.world/backend/public/img/flags/IN.png';
    }

    
    return (
      <>
      <HeaderContainer />
        <div className="pro_midcontianer mid_container">
        <Translator 
                from={this.state.preselectedLanguage}
                to={this.state.selectedLanguage}
                googleApiKey={serverUrl.googleApiTranslation}
              >
          <div className="main_content">
            <div className="form_sec">
              <div className="container">
                <div className="form_toptxt">
                  <h2 className="form_head"><a href="#" className="Darkblue_btn"><i className="far fa-user"></i></a><Translate>Register - {registerType}</Translate></h2>
                  <h3 className="form_subhead"><Translate>All fields with an </Translate>"<span className="txt_red">*</span>" <Translate>are required.</Translate></h3>
                  
                </div>
                <form className="form-horizontal" role="form">
                <div className="Register_form wht_box">
                  <div className="row margin_10">
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>First Name</Translate><span className="txt_red">*</span></label>
                        <input name="firstname" type="text" onChange={this.handleInputChange} className="form-control title_check  border-error" name="firstname" placeholder="Enter First Name" required />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt"><Translate>Middle Name</Translate></label>
                        <input id="middle_name" type="text" onChange={this.handleInputChange} className="form-control" name="middlename" placeholder="Enter Middle Name"/>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Last Name</Translate><span className="txt_red">*</span></label>
                        <input name="lastname" type="text" onChange={this.handleInputChange} className="form-control title_check  border-error" name="lastname"  placeholder="Enter Last Name" required />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                     <label className="label_txt required_fld"><Translate>Date of Birth (DD/MM/YYYY)</Translate><span className="txt_red">*</span></label>
                     <div className="row gap_10">
                        <div className="col-4">
                             <div className="form-group">
                        <select name="birth_year" onChange={this.handleInputChangeYear} className="form-control title_check  border-error" required>
                                <option value="">Select Year</option>
                                {optionsyear.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}                
                            </select>
                      </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                       <select id="birth_month" name="birth_month" onChange={this.handleInputChangeMonth} className="form-control title_check  border-error" required>
                                <option value="">Select Month</option>
                                {optionsmonth.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}
                            </select>
                      </div>
                        </div>
                        <div className="col-4">
                             <div className="form-group">
                                  <select name="birth_date" onChange={this.handleInputChange} className="form-control  title_check  border-error" required >
                                <option value="">Select Date</option>
                                {optionsday.map(({ value, label }, index) =>
                                  <option key={value} value={value} >{label}</option>
                                )}
                            </select>
                          </div>
                        </div>
                     </div>

                    </div>
          <div className="col-lg-4 col-sm-6">
                        <div className="form-group">
                            <label className="label_txt required_fld"><Translate>E-Mail Address</Translate><span className="txt_red">*</span></label>
                            <input name="email" type="email" onChange={this.handleInputChange} className="form-control title_check  border-error" name="email" placeholder="Enter E-Mail Address" required />
                          </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="form-group">
                            <label className="label_txt required_fld"><Translate>Confirm E-Mail Address</Translate><span className="txt_red">*</span></label>
                            <input name="email_confirm" type="email" onChange={this.handleInputChange} className="form-control title_check  border-error" name="email_confirm" placeholder="Confirm E-Mail Address" required />
                          </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Password</Translate><span className="txt_red">*</span></label>
                        <input name="password" type="password" onChange={this.handleInputChange} className="form-control title_check  border-error" placeholder="Enter Password" required />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Confirm Password</Translate><span className="txt_red">*</span></label>
                        <input name="password_confirmation" type="password" onChange={this.handleInputChange} className="form-control title_check  border-error" name="password_confirmation" placeholder="Confirm Password" required />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group">
                        <label className="label_txt required_fld"><Translate>Password Hint</Translate><span className="txt_red">*</span></label>
                        <input name="password_hint" type="text" onChange={this.handleInputChange} className="form-control title_check  border-error" name="password_hint" placeholder="Password Hint" required />
                      </div>
                    </div>

                    
                      
                        <div className="col-sm-6 col-lg-4">
                          <div className="form-group">
                            <label className="label_txt required_fld"><Translate>Mobile Phone Number</Translate><span className="txt_red">*</span></label>
                            <div className="row gap_10 phn_div">
                              <div className="col-sm-4">
                                <div className="dropdown">
                                  <button type="button" className="form-control dropdown-toggle" onClick={ this.toggleMenu }>
                                      <img src={codeimgone}/>
                                      +{codeNameone}
                                  </button>                       
                                  <ul className={"dropdown-menu form_cont_menu " + show}>
                                    {countryData !==''?
                                      countryData.map(({ name, flagurl, flagname, phonecode}, index) => <li key={index}><a href="#" name="mobile_phone_country_code" value="" onClick={() => this.handleInputChangeOtherCountry(flagname,phonecode,flagurl)} ><a href="#"><img src={flagurl + flagname}/>{" +"+phonecode+" "+name}</a></a>
                                    </li>) : ""}
                                  </ul>
                                </div>
                              </div>
                              <div className="col-sm-8">
                                <input name="mobile_no" type="number" onChange={this.handleInputChange} className="form-control title_check  border-error" name="mobile_no" placeholder="Enter your phone" required />
                              </div>
                            </div>
                          </div>
                        </div>
                        {CountryName !=='CN' ?
                        <div className="col-sm-6 col-lg-4">
                          <div className="form-group">
                            <label className="label_txt"><Translate>Can we send you updates via WhatsApp?</Translate></label>
                            <div className="radio_boxes">
                              <span className="btn_box">
                                <input type="radio" id="what_appone" onChange={this.handleInputChangeRadio} name="what_app" value="1" checked={this.state.what_app === '1'}/>
                                <label htmlFor="what_appone"><span><Translate>Yes</Translate></span></label>
                              </span>
                              <span className="btn_box">
                                <input type="radio" id="what_app" onChange={this.handleInputChangeRadio} name="what_app" value="0" checked={this.state.what_app === '0'}/>
                                <label htmlFor="what_app"><span><Translate>No</Translate></span></label>
                              </span>
                            </div>
                          </div>
                        </div>
                        :""}
                        {CountryName ==='CN' ?
                        <div className="col-sm-6 col-lg-4">
                          <div className="form-group">
                            <label className="label_txt"><Translate>Can we send you updates via WeChat?</Translate></label>
                            <div className="radio_boxes">
                              <span className="btn_box">
                                <input type="radio" id="we_chata" onChange={this.handleInputChangeRadio} name="we_chat" value="1" checked={this.state.we_chat === '1'}/>
                                <label htmlFor="we_chata"><span><Translate>Yes</Translate></span></label>
                              </span>
                              <span className="btn_box">
                                <input type="radio" id="we_chat" onChange={this.handleInputChangeRadio} name="we_chat" value="0" checked={this.state.we_chat === '0'} />
                                <label htmlFor="we_chat"><span><Translate>No</Translate></span></label>
                              </span>
                            </div>
                          </div>
                        </div>
                        :""}

                        {/* {userType !=='Buyer' && userType !=='Advertiser' && this.state.buttonCondition === 1 ? 
                        <div className="col-sm-6 col-lg-4">
                          <div className="form-group">
                            <label className="label_txt"><Translate>Affilate code available?</Translate></label>
                            <div className="radio_boxes">
                              <span className="btn_box">
                                <input type="radio" id="aff_code" onChange={this.handleInputChangeRadio} name="aff_code" value="1" checked={this.state.aff_code === '1'}/>
                                <label htmlFor="aff_code"><span><Translate>Yes</Translate></span></label>
                              </span>
                              <span className="btn_box">
                                <input type="radio" id="aff_codedata" onChange={this.handleInputChangeRadio} name="aff_code" value="0" checked={this.state.aff_code === '0'} />
                                <label htmlFor="aff_codedata"><span><Translate>No</Translate></span></label>
                              </span>
                            </div>
                          </div>
                        </div>
                        : ""}*/}

                        {userType !=='Buyer' && userType !=='Advertiser' && this.state.aff_code === '1' ? 
                            <div className="col-lg-4 col-sm-6">
                              <div className="form-group">
                                <label className="label_txt required_fld"><Translate>Primary Affiliate</Translate><span className="txt_red">*</span></label>
                                <input type="text" name="affiliate_code" onChange={this.handleInputChange}  onBlur={this.handleAffiliateChange} className="form-control title_check  border-error" placeholder={"Default: "+DefaultAffiliate}
                                  required />
                                  <p className={msgClass}>{responcedata}</p>
                              </div>
                            </div>
                          : ""}
           
                   
                  
                  <div className="loader text-center">
                  <span>
                          <div className="form-group captcha ">
                                  <ReCAPTCHA className="cap_block"
                                    sitekey="6LdKpzgUAAAAAINfiDD4ryBsLnTUD8_36k-m7J4t"
                                    onChange={onChange}
                                  />
                          </div>
                        </span>      
                    <div className="form-group">
                      <button type="button" onClick={this.handleFormSubmit} className="btn btn_dflt blue_btn blu_btn_shadow"><Translate>Register</Translate></button>
                    </div>
                  </div>
                
              </div>
            </div>
            </form>
              {optionserror.length >0 ?
              <Modal show={this.state.isOpen} onHide={this.closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title><Translate>Error Message</Translate></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {optionserror.map(({ msgerror }, index) => <p key={index} >{msgerror}</p>)}
              </Modal.Body>
              <Modal.Footer>
                <button variant="secondary" onClick={this.closeModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
           :"" }

           {msgMain !=='' ?
              <Modal show={this.state.isOpen} onHide={this.closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title><Translate>Message</Translate></Modal.Title>
                  </Modal.Header>       
                  <Modal.Body>
                  <p><Translate>{msgMain}</Translate></p>
              </Modal.Body>
              <Modal.Footer>
                <button variant="secondary" onClick={this.closeModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
           :"" }
          </div>
          </div>
          </div>
          </Translator>
          {/* Footer */}
          <FooterContainer />
          </div>
          </>
    )
  }
}





