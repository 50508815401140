import React, { Component } from 'react'
import FooterContainer from '../../components/footer/footerContainer'
import HeaderContainer from '../../components/header/headerContainer'
import MainMenuContainer from '../../components/mainMenu/MainMenuContainer'
import serverUrl from '../../configs/constant'
import './stylesheets/productResult.css'
import {isMobile} from 'react-device-detect';
import jumpTo,{go,jumpToData,goData} from '../../modules/Navigation'
import ProductSlider from '../marketplaceHome/components/ProductSlider'
import Modal from "react-bootstrap/Modal";
import Pagination from "react-js-pagination";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Translator, Translate} from 'react-auto-translate';

export default class productResult extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    var marketSegmentData ='';
    if(props.location.state.market_segment){
      var marketSegmentData = props.location.state.market_segment;
    }
    this.state = {
      userdata : localStorage.getItem('userData'),
      keyword:'',
      catVal:'',
      subcatID:'',
      isTop: true,
      isMobile: true,
      addcart:'Add To Cart',
      addcarttext:'fas fa-shopping-cart',
      addwish:'Wishlist',
      addwishtext:'far fa-heart',
      nameData:props.location.state.name,
      codeData:props.location.state.code,
      codeDataMar:props.location.state.code,
      condition:props.location.state.condition,
      conditionsend:props.location.state,
      sort_by:'',
      market_segment:marketSegmentData,
      devicetype:localStorage.getItem('devicetype'),
      deviceos:localStorage.getItem('deviceos'),
      devicebrowser:localStorage.getItem('devicebrowser'),
      latitude: localStorage.getItem('latitude'),
      longitude:localStorage.getItem('longitude'),
      userdevice:localStorage.getItem('userdevice'),
      selectedLanguage:localStorage.getItem('selectedLanguage'),
      preselectedLanguage:localStorage.getItem('preselectedLanguage'),
    };
    var offset = 0;
    var sortBy = "";
    
    // var BackVar= localStorage.getItem("BackVar");
    // if(BackVar == 1){
    //     sortBy= localStorage.getItem("sortBy");
    //     offset= localStorage.getItem("offset");
    // }else{
    //   localStorage.setItem("offset",offset);
    //   localStorage.setItem("sortBy",sortBy);
    // }
    let requestdata = props.location.state;
    requestdata.userdata= localStorage.getItem('userData');
    requestdata.sort_by= sortBy;
    requestdata.offset=offset;
    requestdata.devicetype= localStorage.getItem('devicetype');
    requestdata.deviceos= localStorage.getItem('deviceos');
    requestdata.devicebrowser= localStorage.getItem('devicebrowser');
    requestdata.latitude= localStorage.getItem('latitude');
    requestdata.longitude= localStorage.getItem('longitude');
    requestdata.userdevice= localStorage.getItem('userdevice');
    this.props.getProductLocation(requestdata);
  }

  componentDidMount() {
    window.scrollTo(0, 1);
    this.props.getCategoryData()
     document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 550;
      const isMobileTop =window.scrollY < 460;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
      if (isMobileTop !== this.state.isMobileTop) {
          this.setState({ isMobileTop })
      }
    });
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    let requestdata= {};
     requestdata.catVal= '';
      requestdata.subcatID='';
      this.setState({ category: '' })
      this.props.getSubCategoryData(requestdata);
      this.setState({ subcategoryone: '' })
      this.props.getSubCategoryOneData(requestdata);
      this.setState({ subcategorytwo: '' })
      this.props.getSubCategoryTwoData(requestdata);
  }

  handleChange = (event) => { 
    window.scrollTo(0, 0);
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by=event.target.value;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= this.state.catVal;
      requestdata.subcatID= this.state.subcatID;
      this.setState({ sort_by: event.target.value });
      requestdata.offset=0;
      this.setState({currentPage:1});
      this.props.getProductLocation(requestdata);
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by=event.target.value;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= this.state.catVal;
      requestdata.subcatID= this.state.subcatID;
      this.setState({ sort_by: event.target.value });
      requestdata.offset=0;
      this.setState({currentPage:1});
      this.props.getProductLocation(requestdata);
    }
  }

  handleChangeFilter = (event) => {  
    window.scrollTo(0, 0);
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      requestdata.userdata=this.state.userdata;
      requestdata.market_segment=event.target.value;
      this.setState({ market_segment: event.target.value });
      requestdata.search_data=this.state.keyword;
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= this.state.catVal;
      requestdata.subcatID= this.state.subcatID;
      this.setState({currentPage:1});
      this.props.getProductLocation(requestdata);
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.userdata=this.state.userdata;
      requestdata.market_segment=event.target.value;
      this.setState({ market_segment: event.target.value });
      requestdata.search_data=this.state.keyword;
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= this.state.catVal;
      requestdata.subcatID= this.state.subcatID;
      this.setState({currentPage:1});
      this.props.getProductLocation(requestdata);
    } 
  }

  handleChangeKeyword = (event) => {
    this.setState({ keyword: event.target.value });
  }

handleClickCart= (event) => {
    const MySwal = withReactContent(Swal);
    let requestdata= {};
    requestdata.gbxproductid = event;
    requestdata.userdata = localStorage.getItem('userData');
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    fetch(serverUrl.serverUrl+'/api/buyer/add-shoping-cart', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestdata),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === '1'){
        this.setState({ addcart: 'Added',addcarttext: 'fas fa-check'});
      }else if(data.status === '0'){
          MySwal.fire('<p>'+data.msg+'</p>')
        // useAlert().error(data.msg);
      }
      // console.log('Success:', data);
    })
    .catch((error) => {
      // console.error('Error:', error);
    });
  }



handleClickWish= (event) => {
  // const alert = useAlert();
    const MySwal = withReactContent(Swal);
    let requestdata= {};
    requestdata.gbxproductid = event;
    requestdata.userdata = localStorage.getItem('userData');
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    fetch(serverUrl.serverUrl+'/api/buyer/add-wish-list', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestdata),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === '1'){
        this.setState({ addwish:'Wishlisted',addwishtext: 'fas fa-check'});
      }else if(data.status === '0'){
          MySwal.fire('<p>'+data.msg+'</p>')
        // useAlert().error(data.msg);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }  

  handleClickKeyword = (e) => {
    e.preventDefault()
    this.refs.searchByData.blur();
    const requestdata=this.state.dataCode;
     if(this.state.keyword !=="" && requestdata !== undefined){
     window.scrollTo(0, 0);
     requestdata.userdata=this.state.userdata;
     requestdata.sort_by= this.state.sort_by;
     requestdata.offset=0;
     requestdata.market_segment= this.state.market_segment;
     requestdata.search_data=this.state.keyword;
     requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= this.state.catVal;
      requestdata.subcatID= this.state.subcatID;
     this.setState({currentPage:1});
     this.props.getProductLocation(requestdata);
     }else{
        const requestdata= this.state.conditionsend;
        window.scrollTo(0, 0);
       requestdata.userdata=this.state.userdata;
       requestdata.sort_by= this.state.sort_by;
       requestdata.offset=0;
       requestdata.market_segment= this.state.market_segment;
       requestdata.search_data=this.state.keyword;
       requestdata.devicetype= this.state.devicetype;
        requestdata.deviceos= this.state.deviceos;
        requestdata.devicebrowser= this.state.devicebrowser;
        requestdata.latitude= this.state.latitude;
        requestdata.longitude= this.state.longitude;
        requestdata.userdevice= this.state.userdevice;
        requestdata.catVal= this.state.catVal;
        requestdata.subcatID= this.state.subcatID;
       this.setState({currentPage:1});
       this.props.getProductLocation(requestdata);
     }
  }

  handlePageClick = (data,cond) => {
    let requestdata = data;
    requestdata.devicetype= this.state.devicetype;
    requestdata.deviceos= this.state.deviceos;
    requestdata.devicebrowser= this.state.devicebrowser;
    requestdata.latitude= this.state.latitude;
    requestdata.longitude= this.state.longitude;
    requestdata.userdevice= this.state.userdevice;
    requestdata.catVal= this.state.catVal;
    requestdata.subcatID= this.state.subcatID;
    this.setState({ nameData: data.name,codeData:data.code,dataCode:data,condition:0 });
    this.props.getProductLocation(requestdata);
  }

  handleClick = (offset) => {
    window.scrollTo(0, 0);
    let offsetDataMain = offset - 1;
    let offsetData = 12 * offsetDataMain;
    localStorage.setItem("offset",offsetData);
    // const requestdata= this.state.conditionsend;
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by= this.state.sort_by;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= this.state.catVal;
      requestdata.subcatID= this.state.subcatID;
      requestdata.offset=offsetData;
          console.log('requestdata',requestdata);
      this.props.getProductLocation(requestdata);
      this.setState({
        currentPage: offset
      });
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by= this.state.sort_by;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= this.state.catVal;
      requestdata.subcatID= this.state.subcatID;
      requestdata.offset=offsetData;
          console.log('requestdata',requestdata);
      this.props.getProductLocation(requestdata);
      this.setState({
        currentPage: offset
      });
    }
  }

  handleClickView = (viewdata) => {
    if(viewdata === 1){
      var viewdataChange = 'grid';
    }else{
      var viewdataChange = 'list';
    }
    this.setState({
      marketplaceview: viewdataChange
    });
  }

  handleDataCategorySearch = (subcatID,catVal) => {
    window.scrollTo(0, 0);
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      // const requestdata= this.state.conditionsend;
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.market_segment= this.state.market_segment;
      requestdata.search_data=this.state.keyword;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= catVal;
      requestdata.subcatID=subcatID;
      this.setState({
        currentPage:1,
        isOpen: false,
        catVal:catVal,
        subcatID:subcatID,
        categoryOneData:[],
        categoryTwoData:[],
        categoryThreeData:[],
      });
      this.props.getProductLocation(requestdata);
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by= this.state.sort_by;
      requestdata.offset=0;
      requestdata.market_segment= this.state.market_segment;
      requestdata.search_data=this.state.keyword;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= catVal;
      requestdata.subcatID=subcatID;
      this.setState({
        currentPage:1,
        isOpen: false,
        catVal:catVal,
        subcatID:subcatID,
        categoryOneData:[],
        categoryTwoData:[],
        categoryThreeData:[],
      });
      this.props.getProductLocation(requestdata);
    }
  }

  handleDataClick = (subcatID,catVal) => {
    let requestdata= {};
    requestdata.catVal= catVal;
    requestdata.subcatID=subcatID;
    if(catVal === 1){
      this.props.getSubCategoryData(requestdata);
    }else if(catVal === 2){
      this.props.getSubCategoryOneData(requestdata);
    }else if(catVal === 3){
      this.props.getSubCategoryTwoData(requestdata);
    }
  }

  resetData = (event) => {
    window.scrollTo(0, 0);
    var offset = 0;
    var sortBy = "";
    
    const requestdata=this.state.dataCode;
    if(requestdata !== undefined){
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by=sortBy;
      requestdata.offset=offset;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= '';
      requestdata.subcatID= '';
      requestdata.marketplacesort = '';
      requestdata.filterCat = '';
      requestdata.search_data = '';
      requestdata.market_segment = '';
      this.setState({
        currentPage:1,
        keyword:'',
        category:'',
        sort_by:'',
        catVal:'',
        subcatID:'',
        market_segment:'',
        subcategoryone:'',
        subcategorytwo:'',
        subcategorythree:'',
        marketplacesort:'',
        filterCat:'',
      });

      this.props.getProductLocation(requestdata);
    }else{
      const requestdata= this.state.conditionsend;
      requestdata.userdata=this.state.userdata;
      requestdata.sort_by=sortBy;
      requestdata.offset=offset;
      requestdata.search_data=this.state.keyword;
      requestdata.market_segment=this.state.market_segment;
      requestdata.devicetype= this.state.devicetype;
      requestdata.deviceos= this.state.deviceos;
      requestdata.devicebrowser= this.state.devicebrowser;
      requestdata.latitude= this.state.latitude;
      requestdata.longitude= this.state.longitude;
      requestdata.userdevice= this.state.userdevice;
      requestdata.catVal= '';
      requestdata.subcatID= '';
      requestdata.marketplacesort = '';
      requestdata.filterCat = '';
      requestdata.search_data = '';
      requestdata.market_segment = '';
      this.setState({
        currentPage:1,
        keyword:'',
        category:'',
        sort_by:'',
        catVal:'',
        subcatID:'',
        market_segment:'',
        subcategoryone:'',
        subcategorytwo:'',
        subcategorythree:'',
        marketplacesort:'',
        filterCat:'',
      });

      this.props.getProductLocation(requestdata);
    }

  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });


  render() {
    const loaderAd = require('../../assets/images/loaderAd.gif');
    let loaderHTML = false;
    let productListData = null;
    let productListCount = null;
    let ProductCountPagi = 0;
    let productCount = "";
    const productDetailsMain  = this.props.productListSearch;
    const productDetails  = this.props.productList;
    const condition  = this.state.condition;
    const market_segment  = this.state.market_segment;
    const name  = this.state.nameData;
    const code  = this.state.codeData+'.png';
    let locationLogo ='';
    if(condition === 1){
      if(this.state.codeDataMar !=='' && this.state.codeDataMar !== undefined){
        let codeMain  = this.state.codeDataMar+'.png';
        locationLogo = require('../../assets/images/MarketSeg/'+codeMain);
      }else{
        locationLogo = require('../../assets/images/MarketSeg/'+code);
      }
      
    }else{
      locationLogo =  require('../../assets/images/flags/'+code);
    }
    

    if(productDetailsMain !== null && productDetailsMain !== undefined){
      const productDetails  = productDetailsMain;
    }

    const marketplaceType = "grid";
    // let currentPage = 1;

    const marketSagment = [
                  {'name':'Filter by Market Segment','id':''},
                  {'name':'Business to Business (B2B)','id':'B2B'},
                  {'name':'Business To Consumer (B2C)','id':'B2C'},
                  {'name':'Business To Government (B2G)','id':'B2G'},
                  {'name':'Manufacturer to Business (M2B)','id':'M2B'},
                  {'name':'Manufacturer to Consumer (M2C)','id':'M2C'},                                                              
                  {'name':'Manufacturer to Government (M2G)','id':'M2G'},
                  {'name':'Government to Government (G2G)','id':'G2G'},
                  {'name':'Government to Business (G2B)','id':'G2B'},
                  {'name':'Consumer to Consumer (C2C)','id':'C2C'}
                ];
    const optionsagment = [];
    var objsagment = {}

    for(var i = 0; i < marketSagment.length; i++) {
      var objsagment = {};

      objsagment['value'] = marketSagment[i].id;
      objsagment['label'] = marketSagment[i].name;
      optionsagment.push(objsagment);
    }

    const sortBy = [
                {'name':'Sort by', 'val':'','status':'','sortBy':''},
                {'name':'Price-Low to High', 'val':'UserPrice','status':1,'sortBy':'Low'},
                {'name':'Price-High to Low', 'val':'UserPrice','status':0,'sortBy':'High'},
                {'name':'Title A-Z', 'val':'ProductTitle','status':0,'sortBy':'A'},
                {'name':'Title Z-A', 'val':'ProductTitle','status':1,'sortBy':'Z'},
                {'name':'Most Viewed', 'val':'Most_Viewed','status':1,'sortBy':'View'},
                {'name':'Bestsellers', 'val':'Bestsellers','status':1,'sortBy':'Bestsellers'},
                {'name':'Reset', 'val':'','status':1,'sortBy':'Reset'}
              ];

    const optionsortby = [];
    var objsortby = {}

    const optionpagi = [];
    var objpagi = {}

    for(var i = 0; i < sortBy.length; i++) {
      var objsortby = {};

      objsortby['value'] = sortBy[i].val;
      objsortby['label'] = sortBy[i].name;
      objsortby['sortby'] = sortBy[i].sortBy;
      objsortby['status'] = sortBy[i].status;
      optionsortby.push(objsortby);
    }

    if(productDetails !== null && productDetails !== undefined){
        loaderHTML = true;
        productCount = productDetails.product_count;
        productListData = productDetails.product_list;
        productListCount = productDetails.product_list.length;
        const pageSize = 12;
        if(productListCount > 0){
          ProductCountPagi = productDetails.product_list[0].ProductCountPagi;
          for(var i = 1; i < ProductCountPagi + 1; i++) {
            var objpagi = {};
            
            objpagi['value'] = i;
            
            optionpagi.push(objpagi);
          }
          const totalPageCount = Math.ceil(productDetails.product_list[0].ProductCountPagi / pageSize);
        }
    }


    const categoryDetails  = this.props.categoryData;
    const subOneCategoryDetails  = this.props.subcategoryData;
    const subTwoCategoryDetails  = this.props.subonecategoryData;
    const subThreeCategoryDetails  = this.props.subtwocategoryData;
    const categoryData = [];
    const categoryOneData = [];
    const categoryTwoData = [];
    const categoryThreeData = [];

    

    if(categoryDetails !== null && categoryDetails !== undefined){
        
        const categoryListData = categoryDetails.Category;
        

      for(var i = 0; i < categoryListData.length; i++) {
          var categoryDataMain = {};
          categoryDataMain['id'] = categoryListData[i].id;
          categoryDataMain['category_name'] = categoryListData[i].category_name;
          categoryDataMain['Count'] = categoryListData[i].Count;
          categoryData.push(categoryDataMain);
      }
      if(subOneCategoryDetails !== null && subOneCategoryDetails !== undefined && subOneCategoryDetails !== ''){

          const categoryOneListData = subOneCategoryDetails.Category;

          for(var i = 0; i < categoryOneListData.length; i++) {
            var categoryOneDataMain = {};
            categoryOneDataMain['id'] = categoryOneListData[i].id;
            categoryOneDataMain['category_name'] = categoryOneListData[i].subcat1_name;
            categoryOneDataMain['Count'] = categoryOneListData[i].subcatOneCount;
            categoryOneData.push(categoryOneDataMain);
        }
      }

      if(subTwoCategoryDetails !== null && subTwoCategoryDetails !== undefined && subTwoCategoryDetails !== ''){

          const categoryTwoListData = subTwoCategoryDetails.Category;

          for(var i = 0; i < categoryTwoListData.length; i++) {
            var categoryTwoDataMain = {};
            categoryTwoDataMain['id'] = categoryTwoListData[i].id;
            categoryTwoDataMain['category_name'] = categoryTwoListData[i].subcat2_name;
            categoryTwoDataMain['Count'] = categoryTwoListData[i].subcatTwoCount;
            categoryTwoData.push(categoryTwoDataMain);
        }
      }

      if(subThreeCategoryDetails !== null && subThreeCategoryDetails !== undefined && subThreeCategoryDetails !== ''){

          const categoryThreeListData = subThreeCategoryDetails.Category;

          for(var i = 0; i < categoryThreeListData.length; i++) {
            var categoryThreeDataMain = {};
            categoryThreeDataMain['id'] = categoryThreeListData[i].id;
            categoryThreeDataMain['category_name'] = categoryThreeListData[i].subcat3_name;
            categoryThreeDataMain['Count'] = categoryThreeListData[i].subcatThreeCount;
            categoryThreeData.push(categoryThreeDataMain);
        }
      }

    }

    // export const usePagination = ({
    //   totalCount,
    //   pageSize,
    //   siblingCount = 1,
    //   currentPage
    // }) => {
    // const paginationRange = useMemo(() => {
    //      // Our implementation logic will go here 
          
    //   }, [totalCount, pageSize, siblingCount, currentPage]);

    //   return paginationRange;
    // };


    // const onPageChange = (value) => {
    //   console.log('value',value);
    //   // currentPage = value;
    //   // onPageChange(currentPage + 1);
    // };

    // const onNext = () => {
    //   onPageChange(currentPage + 1);
    // };

    // const onPrevious = () => {
    //   onPageChange(currentPage - 1);
    // }

    return (
      <span>
      <HeaderContainer />
      {isMobile === false ?
        <MainMenuContainer /> 
                   : ""}
        <Translator 
            from={this.state.preselectedLanguage}
            to={this.state.selectedLanguage}
            googleApiKey={serverUrl.googleApiTranslation}
          >
          <div className="resultBanner_box indianflagClass" style={{backgroundImage:"url("+locationLogo+")", backgroundSize: '100% 100%', padding:" 68px 0 24px"}}>
            <div className="container" align="center">
              {loaderHTML === false ?
            <h2>Loading <img className="loader_Ad" src={loaderAd}/></h2> :""
          }
              <h1 className="main_heading" style={{textShadow:"2px 2px 0px rgba(0, 0, 0, 0.50)"}}>{productCount}<br/><br/>{condition === 1 ? <span>{name}</span> : <span>Products in<br/><br/>{name}</span>}
              </h1>
            </div>
          </div>  
          {isMobile === true ? 
              <span className={this.state.isMobileTop ? 'productResultMenu' : 'productResultMenuFixed' }>
               <MainMenuContainer />

              </span>
          : "" }  

          {isMobile === false ? 
                <div className={this.state.isTop ? 'Form_holder productResult_holder' : 'Form_fixed Top_change Form_holder'}>
                  <div className="container">
                    <div className="Form_box">
                      <div className="row Form_row">
                        <div className="col-sm-2 Form_col">
                          <div className="col-xs-11 col-sm-11 col-md-11 col-lg-10 box_col">  
                              <div className="button_box">          
                                <a href="#" className="btn blue_btn coman_btn" onClick={this.openModal}><Translate>Search by Categories</Translate></a>
                              </div>
                          </div>
                          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-2 box_col">
                            <div className="grid_icon" >
                            <a title="Refresh" onClick={this.resetData} className="refreshBtn"><i className="fa fa-retweet"></i></a>
                          </div>
                          </div>
                        </div>
                        <div className="col-sm-5 Form_col">
                          <form className="navbar-form dataClass" role="search" onSubmit={this.handleClickKeyword}>
                            <span className="voice_serch" title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></span>
                          <div className="input-group add-on">
                            <input className="form-control dataBorder" ref="searchByData" name="searchBy" onChange={this.handleChangeKeyword} placeholder="Search by Keyword. . ." id="srch-term" type="text"/>
                            <div className="input-group-btn input-group-addon dataWidth">
                            <button className="btn blue_btn search_btn" onClick ={this.handleClickKeyword} type="button"><i className="fas fa-search"></i></button>
                            </div>
                          </div>
                          </form>
                        </div>
                        <div className="col-sm-4 Form_col">
                          <div className=" col-sm-8 box_col"> 
                            <div className="button_box">  
                              <select className="form-control filterBar" name="filterCat" value={this.state.filterCat} onChange={this.handleChangeFilter}>
                                {optionsagment.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}
                              </select>         
                            </div>
                          </div>
                          <div className=" col-sm-4 box_col">        
                                <div className="button_box">
                                  <select className="form-control sortBar" name="marketplacesort" value={this.state.marketplacesort} onChange={this.handleChange}>
                                    {optionsortby.map(({ value, label, status, sortby }, index) => <option key={index} value={sortby}>{label}</option>)}
                                  </select>
                                </div>    
                          </div>
                        </div>
                        <div className="col-sm-1 Form_col">
                          <div className="row box_row">    
                            <div className="grid_icon homeGrid">
                                <a title="Grid" alt="Grid" className="Grid Grid_active"><i className="fas fa-th"></i></a>
                                <a  title="List" alt="List" onClick={() => {this.handleClickView(2)}} className={this.state.marketplaceview === 'list' ? 'Grid List Grid_active' :'Grid List'} ><i className="fas fa-list-ul"></i></a>
                            </div>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
             :
                <div className={this.state.isMobileTop ? 'Form_holder' : 'Form_fixed Form_holder'}>
                  <div className="container">
                    <div className="Form_box">
                      <div className="row Form_row">
                        <div className="col-sm-2 Form_col"> 
                              <div className="button_box">          
                                <a href="#" className="btn blue_btn coman_btn" onClick={this.openModal}><Translate>Search by Categories</Translate></a>
                              </div>
                        </div>
                      <div className="col-sm-4 Form_col ">
                          <form className="navbar-form dataClass productResultSearch" role="search" onSubmit={this.handleClickKeyword}>
                            <span className="voice_serch" title="Search by Voice Coming Soon"><i className="fas fa-microphone"></i></span>
                           <div className="input-group add-on">
                             <input className="form-control dataBorder" ref="searchByData" name="searchBy" onChange={this.handleChangeKeyword} placeholder="Search by Keyword" id="srch-term" type="text"/>
                            <div className="input-group-btn input-group-addon dataWidth">
                             <button className="btn blue_btn search_btn" onClick ={this.handleClickKeyword} type="button"><i className="fas fa-search"></i></button>
                            </div>
                           </div>
                          </form>
                      </div>
                      <div className="col-sm-3 Form_col">
                        <div className="button_box">  
                            <select className="form-control filterBar" name="filterCat" value={this.state.filterCat} onChange={this.handleChangeFilter}>
                              {optionsagment.map(({ value, label }, index) => <option key={value} value={value} >{label}</option>)}
                            </select>         
                        </div>
                      </div>
                      <div className="col-sm-3 Form_col">
                        <div className="row box_row">
                          <div className=" col-sm-10 box_col col-xs-10 col-md-10 col-lg-9 box_col">        
                            <div className="button_box">
                             <select className="form-control sortBar" name="marketplacesort" value={this.state.marketplacesort} onChange={this.handleChange}>
                              {optionsortby.map(({ value, label, status, sortby }, index) => <option key={index} value={sortby} >{label}</option>)}
                             </select>
                            </div>    
                          </div>    
                          <div className=" col-sm-2 col-xs-2 col-md-2 col-lg-3 box_col text-right">
                            <div className="grid_icon">
                             <a title="Grid" alt="Grid" className="Grid Grid_active"><i className="fas fa-th"></i></a>
                             <a title="List" alt="List" className="Grid List "><i className="fas fa-list-ul"></i></a>
                            </div>
                          </div>
                        </div>  
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              }
          {loaderHTML === false ?
            <h1 align="center"><Translate>Searching</Translate></h1> :""
          }

          {productListCount <= 0 && productListCount !==null  ?
            <h1 align="center"><Translate>No Product Found</Translate></h1> :""
          }

          {marketplaceType ==='grid' && productListData !== undefined && productListData !==null ?
              <div className="Grid_Boxes">
                <div className="container">
                  <div className="row">
                  {productListData.map(pro =>
                    <div key={pro.product_id} className="col-lg-4 col-md-6 col-sm-6 Grid_col">
                      <div className="Grid_Box">
                        <h2 className="MainGrid_txt"><a onClick={() => jumpTo('/product-detail/'+pro.GBXProductID)}><Translate>{pro.ProductTitle}</Translate></a></h2>
                        <div className="thumb_hidden">
                          <span className="img_cellholder">
                            <div className="grid_slider">
                              <ProductSlider productImage={pro.Images} productUrl={pro.Image_path} productId={pro.GBXProductID}/>
                            </div>
                          </span>
                          {pro.isFeatured === 1 ?
                          <div className="feature_stock">
                            <span className="featured_secll">
                              <a className="featured"><Translate>featured</Translate></a>
                            </span>
                          </div>
                          : ""
                          }                  

                          {pro.QTYAvailable === false ?
                          <div className="stock_con">
                            <div className="out_stock">
                              <span className="stock_secll">
                                <a className="out_stockbtn"><Translate>OUT OF STOCK</Translate></a>
                              </span>
                            </div>
                          </div>
                          : ""
                          }

                          {pro.DiscountedStatusData === true ?
                            <div className="sale_bar">
                              <span className="red_barsale"> 
                                Product on sale 
                                <span className="disount_sec"> 
                                  <span className="discount_txt">
                                     {pro.DiscountedPercentage}% 
                                   </span>
                                 off</span> 
                               </span> 
                             </div>
                          : ""
                          }
                        </div>
                        <div className="Grid_Description">
                          <div className="row Descript_row">
                            <div className="col-sm-6 col-6 Grid_column">
                              <h3><Translate>Vendor:</Translate></h3>
                              <div className="Ptxt_box">
                                <a className="tooltip_box" onClick={() => jumpTo('/vendor-store/'+pro.CompanyURL)}>
                                  <span className="Direct_txt">{pro.VendorInfo}</span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/>{pro.VendorProductCount}<br/><Translate>products posted by this vendor</Translate></p>
                                  </span>
                                </a>
                                <br/>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModal"+pro.product_id}><span className="Direct_txt"><Translate>{pro.ProductCity}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.ProductCity}</Translate></b></p>
                                  </span>
                                </a>  
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalState"+pro.product_id}><span className="Direct_txt"><Translate>{pro.ProductProvinceState}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.ProductProvinceState}</Translate></b></p>
                                  </span>
                                </a>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalCountry"+pro.product_id}><span className="Direct_txt"><Translate>{pro.CountryProductName}</Translate></span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass"><Translate>{pro.CountryProductName}</Translate></b></p>
                                  </span>
                                </a>
                                <a className="tooltip_box" data-toggle="modal" data-target={"#exampleModalPostal"+pro.product_id}><span className="Direct_txt">Post Code: {pro.ProductPostalCode}</span>
                                  <span className="tooltiptext_box tooltip-top">
                                    <p><Translate>Click here to see</Translate><br/><Translate>PRODUCTS and VENDORS</Translate><br/> in <b className="upperClass">{pro.ProductPostalCode}</b></p>
                                  </span>
                                </a>    
                              </div>
                            </div>
                            <div className="col-sm-6 col-6 Grid_column">
                              <table className="table_box">
                                <tbody>
                                  <tr>
                                    <td><a className="green tooltip_box" onClick={() => this.handlePageClick({countryname:pro.ProductCountry,name:pro.CountryProductName,code:pro.ProductCountry})}>
                                      <img src={pro.Icon_path+pro.VandorCountry} title="" />
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>Click here to see</Translate><br/>{pro.CountryProductCount}<br/><Translate>products supplied from</Translate><br/>{pro.VendorCountryName}</p>
                                      </span>
                                    </a></td>
                                    <td><a className="Red_box tooltip_box">
                                      <span className="red">{pro.VandorCur} {pro.VandorPrice}</span>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><a className="green tooltip_box" onClick={() => this.handlePageClick({countryname:'US',name:'United States',code:'US'})}>
                                      <img src={pro.Icon_path +pro.USFlag} title="" />
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>Click here to see</Translate><br/>{pro.USProductCount}<br/><Translate>products supplied from</Translate><br/>{pro.USCountryName}</p>
                                      </span>
                                    </a></td>
                                    <td>
                                      <a className="Red_box tooltip_box">
                                      <span className="black">{pro.USCurrency} {pro.USPrice}</span>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><a className="green tooltip_box" onClick={() => this.handlePageClick({countryname:pro.UserCountryCode,name:pro.UserCountryName,code:pro.UserCountryCode})}>
                                      <img src={pro.Icon_path + pro.UserCountry} title="" />
                                      <span className="tooltiptext_box tooltip-top">
                                        <p><Translate>Click here to see</Translate><br/>{pro.UserCountryCount}<br/><Translate>products supplied from </Translate><br/>{pro.UserCountryName}</p>
                                      </span>
                                    </a></td>
                                    <td>
                                      <a className="Red_box tooltip_box">
                                        <span className="green">{pro.Buyer_currency +" "+ pro.UserPriceMain}</span>
                                        {pro.HasDiscount === 1 ?
                                          <span className="tooltiptext_box tooltip-top" >
                                            <p><Translate>Availabe Price by Volume for this Product</Translate></p>
                                            <table className="tooltip_table">
                                              <tbody><tr>
                                                <td><Translate>Up to</Translate></td>
                                                <td><Translate>From</Translate></td>
                                                <td><Translate>to</Translate></td>
                                                <td><Translate>More than</Translate></td>
                                              </tr>
                                              <tr>
                                                <td>{pro.UpToQuantity1 === 0 ? 1 : pro.UpToQuantity1}</td>
                                                <td>{pro.UpToQuantity1 == 0 ? pro.HasMinimumQuantity : pro.UpToQuantity1 + 1}</td>
                                                <td>{pro.UpToQuantity2}</td>
                                                <td>{pro.MoreThanQuantity2}</td>
                                              </tr>
                                              <tr>
                                                <td>{pro.UserPrice === 'NaN' ? "N/A" : pro.UserPrice}</td>
                                                <td colSpan="2">{pro.UserPrice_one}</td>
                                                <td>{pro.UserPrice_sec}</td>
                                              </tr>
                                            </tbody></table>
                                            <div className="Price_txt text-left">
                                              <p><Translate>Above Prices are in </Translate>{pro.Buyer_currency}<img src={pro.Icon_path+pro.UserCountry} title=""/></p>
                                            </div>
                                          </span>
                                        :""}                      
                                      </a>
                                    </td>
                                  </tr>


                                </tbody>
                              </table>
                            </div> 
                              


                          </div>
                          <div className="Ptxt_box">
                            <span className="Direct_txt membersin"><Translate>Member since {pro.VandorDate}</Translate></span>
                          </div>
                        </div>
                        <div className="ReadMore_box">
                          <table className="table_box table">
                            <tbody>
                              <tr>
                                <td>
                                  <a className="green" data-toggle="modal" data-target={"#exampleModalShare"+pro.product_id}><Translate>Share</Translate><i className="long_arrow fas fa-share-alt"></i></a>
                                </td>
                                {pro.QTYAvailable === true && pro.CountryAvailability == true ?
                                <td>
                                {pro.addedwish === false ?
                                  <a className="green tooltip_box" onClick={() => this.handleClickWish(pro.GBXProductID)}>
                                    <i className={this.state.addwishtext}></i>{this.state.addwish}
                                      <span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "Click here in order to add this item to your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span>
                                  </a>
                                  :""}
                                  {pro.addedwish === true ?
                                  <a className="green tooltip_box"><i className="fas fa-check"></i>Wishlisted
                                    <span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "This Item is in your wishlist":"Please login as Buyer in order to add this item to your wishlist"}</Translate></p></span>
                                  </a>
                                  :""}
                                </td>
                                :""}

                                {pro.QTYAvailable === true && pro.CountryAvailability === true ?
                                <td>
                                  {pro.addedshop === false ?
                                  <a className="dark_green tooltip_box" onClick={() => this.handleClickCart(pro.GBXProductID)}>
                                    <i className={this.state.addcarttext}></i>{this.state.addcart}<span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "Click here in order to add this item to your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span>
                                  </a>
                                  :""}
                                  {pro.addedshop === true ?
                                  <a className="dark_green tooltip_box">
                                    <i className="fas fa-check"></i><Translate>Added</Translate><span className="tooltiptext_box"><p><Translate>{pro.UserType !=='' && pro.UserType === 'Buyer' ? "This Item is in your shopping cart":"Please login as Buyer in order to add this item to your shopping cart"}</Translate></p></span>
                                  </a>
                                  :""}
                                </td>
                                :""}
                                {pro.QTYAvailable !== false && pro.CountryAvailability == false ?
                                <td className="OutStock_check">
                                    <span className="checkbox_txt"><Translate>This product is not available for sale in your country</Translate></span>
                                </td>
                                :""}
                                {pro.QTYAvailable === false ?
                                <td className="OutStock_check">
                                  <label className="lable_con">
                                    <input type="checkbox"/>
                                    <span className="checkmark"></span>
                                    <span className="checkbox_txt"><Translate>Click here to get notified when the product becomes available</Translate></span>
                                  </label>
                                </td>
                                :""}
                              </tr>                       
                            </tbody>
                          </table>
                        </div>

                        


                      </div>

                          <div className="modal fade"  id={"exampleModalState"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.VandorProvinceState}</Translate></b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.ProvinceStateProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass"><Translate>{pro.ProductProvinceState}</Translate></b></p>
                                      <button type="button" onClick={() => this.handlePageClick({statename:pro.ProductProvinceState,name:pro.ProductProvinceState,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see </Translate><br/>{pro.ProvinceStateVendorCount}<br/><Translate>VENDORS LOCATED in</Translate> <br/><b className="upperClass"><Translate>{pro.ProductProvinceState}</Translate></b></p>
                                      <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.ProductProvinceState,{statename:pro.ProductProvinceState,name:pro.ProductProvinceState,code:pro.ProductCountry})}  className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="modal fade" id={"exampleModal"+pro.product_id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><Translate>PRODUCTS and VENDORS in </Translate><b className="upperClass"><Translate>{pro.VandorCity}</Translate></b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.CityProductCount}<br/><Translate>PRODUCTS LOCATED in</Translate><br/><b className="upperClass"><Translate>{pro.ProductCity}</Translate></b></p>
                                      <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.handlePageClick({cityname:pro.ProductCity,name:pro.ProductCity,code:pro.ProductCountry})}><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see </Translate><br/>{pro.CityVendorCount}<br/><Translate>VENDORS LOCATED in </Translate><br/><b className="upperClass">{pro.ProductCity}</b></p>
                                      <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.ProductCity,{cityname:pro.ProductCity,name:pro.ProductCity,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>



                            <div className="modal fade" id={"exampleModalPostal"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">PRODUCTS and VENDORS in <b className="upperClass">{pro.VandorPostalCode}</b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.PostalCodeProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass">{pro.ProductPostalCode}</b></p>
                                      <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.handlePageClick({postalname:pro.ProductPostalCode,name:pro.ProductPostalCode,code:pro.ProductCountry})}><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p>C<Translate>lick here to see</Translate> <br/>{pro.PostalCodeVendorCount}<br/><Translate>VENDORS LOCATED in </Translate><br/><b className="upperClass">{pro.ProductPostalCode}</b></p>
                                      <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.ProductPostalCode,{postalname:pro.ProductPostalCode,name:pro.ProductPostalCode,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="modal fade" id={"exampleModalCountry"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">PRODUCTS and VENDORS in <b className="upperClass">{pro.CountryVendorName}</b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.CountryProductCount}<br/><Translate>PRODUCTS LOCATED in </Translate><br/><b className="upperClass"><Translate>{pro.CountryProductName}</Translate></b></p>
                                      <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.handlePageClick({countryname:pro.ProductCountry,name:pro.CountryProductName,code:pro.ProductCountry})}><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      </div>
                                      <div className="col-sm-4 col-xs-4 Grid_column">
                                      <p><Translate>Click here to see</Translate><br/>{pro.CountryVendorCount}<br/><Translate>VENDORS LOCATED in</Translate><br/><b className="upperClass"><Translate>{pro.CountryProductName}</Translate></b></p>
                                      <button type="button" onClick={() => jumpToData('/vendor-result/'+pro.CountryProductName,{countryname:pro.ProductCountry,name:pro.CountryProductName,code:pro.ProductCountry})} className="btn btn-success" data-dismiss="modal"><Translate>Continue</Translate></button>
                                    </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="modal fade" id={"exampleModalShare"+pro.product_id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"><b><Translate>Share This Product</Translate></b></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <a target="_blank" href={"https://twitter.com/intent/tweet?text=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID}><i className="fab fa-twitter-square"></i><Translate>Share via Twitter</Translate></a>
                                    <br/>
                                    <hr/>
                                    <div className="fb-share-button" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button_count" data-size="small"><a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID} className="fb-xfbml-parse-ignore"><i className="fab fa-facebook-square"></i><Translate>Share via Facebook</Translate></a></div>
                                    <br/>
                                    <hr/>
                                    <a target="_blank" href={"https://www.linkedin.com/shareArticle?mini=true&url=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID}><i className="fab fa-linkedin"></i><Translate>Share via LinkedIn</Translate></a>
                                    <span>
                                    <br/>
                                    <hr/>
                                    <a href={"whatsapp://send?text=https://"+serverUrl.serverdomain+"/product-detail/"+pro.GBXProductID} data-action="share/whatsapp/share"><i className="fab fa-whatsapp"></i> <Translate>Share via Whatsapp</Translate></a>
                                    </span>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><Translate>Close</Translate></button>
                                  </div>
                                </div>
                              </div>
                            </div>

                    </div>
                  )}


                  </div>
                </div>
              </div>
            :

            <div></div>

          }
          <div className="pagination_box text-center">
            <Pagination
              activePage={this.state.currentPage}
              itemsCountPerPage={12}
              totalItemsCount={ProductCountPagi}
              pageRangeDisplayed={10}
              onChange={this.handleClick.bind(this)}
            />
          </div>

          <Modal className="Categories_Popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
            <Modal.Header className="blue_btn">
                  <button type="button" className="closeBtnData" onClick={this.closeModal}><i className="far fa-times-circle"></i></button>
                      <h4 className="modal-title withoutuser uprcase"><Translate>All Categories</Translate><span className="icon_spn blue"></span> </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="heading_control">
                
                  {categoryData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,1)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                    <a className="panel_title" onClick={() => this.handleDataClick(id,1)}><i className="fas fa-list-ul"></i></a>
                  </div>)}
                  
              </div>
              <div className="panel_group">
                <div className="panel-body">
                  <div className="row margin_auto">
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 1</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryOneData.map(({ id,category_name,Count }, index) => <div key={index} className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,2)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                          <a className="panel_title" onClick={() => this.handleDataClick(id,2)}><i className="fas fa-list-ul"></i></a>
                        </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 1</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 2</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryTwoData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,3)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                            <a className="panel_title" onClick={() => this.handleDataClick(id,3)}><i className="fas fa-list-ul"></i></a>
                          </div>)}
                        </div>
                        <div className="shadow_box">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 2</Translate></span></a>
                        </div>
                      </div>                      
                    </div>
                    <div className="col-sm-4">
                      <h2 className="Sub_Category"><Translate>Sub Category 3</Translate></h2>
                      <div className="collapse_menu">
                        <div className="shadow_box">
                        {categoryThreeData.map(({ id,category_name,Count }, index) => <div className="panel_heading"><span className="accordion_txt"><a onClick={() => this.handleDataCategorySearch(id,4)}><Translate>{category_name}</Translate></a><span className="noti_spn transition drop_value blue_bg">{Count}</span></span>
                            <a></a>
                          </div>)}
                        </div>
                        <div className="shadow_box" ng-if="lamr.subCat2 ==''">
                          <a href="#" className="SubCtg"><span className="span_box"><Translate>No Sub Category 3</Translate></span>
                          </a>
                        </div>
                      </div>                      
                    </div>          
                  </div>          
                </div>
              </div>

            </Modal.Body>
          </Modal>
          </Translator>
        {/* Footer */}
          <FooterContainer />
      </span>
    )
  }
}





